<script setup lang="ts">
import SVG_BANNER_RIGHT_TRIANGLE from '/assets/svg/banner-right-triangle.svg'
import type { THomeBannerListProps } from '~/types/components'

const { bannerList } = defineProps<{
  bannerList: THomeBannerListProps
}>()
const currentIdx = ref(0)

const activeBarWidth = computed(() => 1 / bannerList.length * 100)
const activeBarX = computed(() => activeBarWidth.value * Number(currentIdx.value))
const lastToFirst = ref(false)

function tapPrevOrNext(i: number) {
  if (i > 1)
    currentIdx.value = (currentIdx.value + 1) % bannerList.length
  else if (currentIdx.value > 0)
    currentIdx.value -= 1
  else
    currentIdx.value = bannerList.length - 1
}

function autoFillZero(num: number) {
  return num < 10 ? `0${num}` : `${num}`
}

function slideEndEvent({ currentSlideIndex }: Record<string, number>) {
  currentIdx.value = currentSlideIndex
}

function slideStartEvent({ currentSlideIndex, slidingToIndex }: Record<string, number>) {
  lastToFirst.value = currentSlideIndex === bannerList.length - 1 && slidingToIndex === 0
}
</script>

<template>
  <div class="relative select-none">
    <Carousel
      v-model="currentIdx" :wrap-around="true" pause-autoplay-on-hover class="z-2 -mt-2 md:-mt-4"
      :autoplay="bannerList.length > 1 ? 5000 : undefined"
      @slide-end="slideEndEvent"
      @slide-start="slideStartEvent"
    >
      <Slide v-for="(item, index) in bannerList" :key="item.gameName" class="banner-slide w-full flex">
        <div class="banner-inner relative h-110 w-full of-hidden rd-4">
          <div class="banner-inner-bg pointer-events-none absolute inset-is-0 bottom-0 w-full flex-center of-hidden rd-4">
            <img v-if="index < 2" class="aspect-ratio-3/1 h-full max-h-100 rd-4 object-cc transition-300 flip-rtl" :src="scaleImage(item.images.bgImage, [1200, 0], 80)" :alt="`banner-img-${item.gameName}`">
            <img v-else v-lazy="scaleImage(item.images.bgImage, [1200, 0], 80)" class="aspect-ratio-3/1 h-full max-h-100 rd-4 object-cc transition-300 flip-rtl" :alt="`banner-img-${item.gameName}`">
          </div>
          <img v-if="index < 1" :src="scaleImage(item.images.roleImage, [1200, 0], 80)" class="pointer-events-none absolute inset-is-0 bottom-0 z-10 aspect-ratio-30/11 h-full w-inherit flip-rtl" :alt="`banner-role-${item.gameName}`">
          <img v-else v-lazy="scaleImage(item.images.roleImage, [1200, 0], 80)" class="pointer-events-none absolute inset-is-0 bottom-0 z-10 aspect-ratio-30/11 h-full w-inherit flip-rtl" :alt="`banner-role-${item.gameName}`">
          <NuxtLink class="absolute inset-is-0 top-0 size-full opacity-0" :to="`/games/${item.seo}.html`" />
        </div>
      </Slide>
    </Carousel>
    <ClientOnly>
      <div class="pointer-events-none absolute inset-is-0 bottom-0 z-11 w-full overflow-hidden rd-4 px-5">
        <div class="h-45 w-full from-transparent to-#000 to-op-70 bg-gradient-to-b" />
      </div>
    </ClientOnly>
    <section class="ctrl-bar absolute inset-is-0 bottom-0 w-full flex justify-between" lt-md="px-10 pb-5 flex-col" md="px-15 pb-10">
      <div class="relative z-13" lt-md="h-42 w-fit max-w-full" md="h-25 w-30%">
        <div
          v-for="(game, idx) in bannerList" :key="game.gameName" class="absolute inset-is-0 top-0 flex cursor-pointer opacity-0 transition-150"
          lt-md="flex-col gap-3" md="items-center gap-4"
          :class="{ 'opacity-100': idx === currentIdx }"
        >
          <div class="left flex items-center">
            <img class="game-logo flip-rtl" lt-md="size-15 rd-3" md="size-25 rd-4" :src="game.icon" :alt="game.gameName">
            <div class="size-10 flex-center rd-50 bg-green-5 -mis-5 lt-md:hidden flip-rtl">
              <SVG_BANNER_RIGHT_TRIANGLE class="size-4" />
            </div>
          </div>
          <div class="flex flex-col">
            <span class="line-clamp-1 max-w-70 w-max text-8 c-#fff font-[Bebas_Neue]">{{ game.gameName }}</span>

            <button class="ld-button pointer-events-none h-11 min-w-33 flex-shrink-0 px-3 py-2 md:hidden ld-button-green" lt-md="mt-5">
              <SVG_BANNER_RIGHT_TRIANGLE class="size-1em" />
              <span>{{ $t('common.playNow') }}</span>
            </button>

            <span class="text-justify text-3.5 c-#fff c-op-50 font-600 lt-md:hidden">{{ $t('common.playNow') }}
              <svg class="mis-3 flip-rtl" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7H11L9 5" stroke="white" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
          </div>
          <NuxtLink class="absolute inset-is-0 top-0 z-12 h-full w-full" :to="`/games/${bannerList[currentIdx].seo}.html?start=1`" />
        </div>
      </div>

      <ClientOnly class="h-6.5">
        <div v-if="bannerList.length > 1" class="flex flex-col" md="items-end justify-end">
          <div class="z-13 flex lt-md:hidden">
            <div v-for="i in 2" :key="i" class="size-10 flex-center cursor-pointer rd-50 bg-#fff bg-op-10 backdrop-blur-20px" :class="i === 2 && 'rotate-180 mis-4'" @click="tapPrevOrNext(i)">
              <svg class="flip-rtl" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 2L5 6L7 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
          <div class="pointer-events-none z-13 mt-5 flex items-center">
            <span class="c-#fff font-[Bebas_Neue] lt-md:hidden">{{ autoFillZero(currentIdx + 1) }}</span>
            <div class="relative h-1 w-15 flex rd-0.5 bg-#fff bg-op-10 backdrop-blur-20px md:mx-3 md:w-30">
              <i
                class="absolute inset-is-0 top-0 h-1 rd-0.5 bg-green-5"
                :class="`${lastToFirst ? `w-0 -inset-is-${activeBarWidth}%` : `transition-300`}`"
                :style="`width: ${activeBarWidth}%; left: ${activeBarX}%`"
              />
            </div>
            <span class="c-#fff font-[Bebas_Neue] lt-md:hidden">{{ autoFillZero(bannerList.length) }}</span>
          </div>
        </div>
      </ClientOnly>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.banner-slide {
  &:hover {
    .banner-inner-bg img {
      transform: scale(1.02);
    }
  }
}

:dir(rtl) {
  .banner-slide {
    &:hover {
      .banner-inner-bg img {
        transform: scale(1.02) scaleX(-1);
      }
    }
  }
}

/* layer: default */
@media (min-width: 768px) {
  .ctrl-bar .game-logo {
    -webkit-mask-image: url(~/assets/svg/banner-mask-shape.svg);
    mask-image: url(~/assets/svg/banner-mask-shape.svg);
    mask-repeat: no-repeat;
  }
}
</style>
