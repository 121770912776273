<script setup lang="ts">
import { API_INDEX_DATA } from '@/api'
import { handleHomeBannerImg } from '~/api/wash-data'
import { EShowMoreBtnStatus } from '~/types/components'

import type * as API_TYPE from '@/types/api'

const { t } = useI18n()

const title = computed(() => t('title.home', 'EasyFun.gg - Play Top Mobile Games Online for Free'))
const description = computed(() => t('description.home', 'Enjoy the top games online for free! Smooth, download-free, lag-free. Simply access EasyFun.gg in your browser.'))
useSeoMeta({
  title,
  ogTitle: title,
  description,
  ogDescription: description,
})

useCacheRoute()

const ONE_PAGE_COUNT = 24
const FIRST_PAGE_COUNT = 48
const pageNumber = ref(1)

const { value, addToCache } = await useDataCache<API_TYPE.IIndexData>(`api:index_data_page_${pageNumber.value}`)
const { data: pageInfo, pending } = await useAsyncData('index-data', async () => {
  if (value)
    return value
  let limit = FIRST_PAGE_COUNT
  if (pageNumber.value !== 1)
    limit = FIRST_PAGE_COUNT + (ONE_PAGE_COUNT * (pageNumber.value - 1))

  const response = await API_INDEX_DATA(limit)
  await addToCache(response, undefined, 900)
  return response
}, {
  transform: handleHomeBannerImg,
  getCachedData: (key, nuxt): API_TYPE.ReturnPromiseType<typeof API_INDEX_DATA> => {
    const res = (nuxt.payload.data[key] || nuxt.static.data[key]) as API_TYPE.ReturnPromiseType<typeof API_INDEX_DATA>
    // 改动页数会导致watch触发而重新获取内容，所以换个角度，内容过多就删回去第一页，这何尝不是一种兼容呢
    if ((res?.games?.size || 0) > FIRST_PAGE_COUNT)
      res.games.data = res.games.data.slice(0, FIRST_PAGE_COUNT)
    return res
  },
  watch: [pageNumber],
  lazy: process.client,
})
</script>

<template>
  <!-- skeleton -->
  <section v-if="pending">
    <div class="view-content">
      <div class="relative h-110 w-full -mt-2 md:-mt-4">
        <div class="absolute inset-is-0 bottom-0 aspect-ratio-3/1 h-100 w-full rd-4 bg-gray-2" />
        <div class="aspect-ratio-30/11 h-full" />
      </div>
    </div>
    <section class="view-content mt-10 flex overflow-x-overlay py-2 -my-2" gap="4 md:6">
      <div v-for="v in 6" :key="v">
        <div class="aspect-ratio-3/4 h-40 flex-shrink-0 bg-gray-2 lg:h-60 md:h-50 group-hover:scale-110" lt-md="rd-3" md="rd-4" />
        <div class="mbs-2 h-3.5 w-60% bg-gray-2 md:hidden" />
      </div>
    </section>
    <div class="grid view-content mt-10" grid-cols="4 sm:5 md:6 lg:8" gap="4 md:6">
      <div v-for="v in 48" :key="v" class="w-full flex flex-col">
        <div class="aspect-ratio-1 w-full bg-gray-2" lt-md="rd-3" md="rd-4" />
        <div class="mbs-2 h-3.5 w-60% bg-gray-2 md:mbs-3" />
        <div class="h-3.5" />
      </div>
    </div>
  </section>

  <HomeBanner v-if="!pending && pageInfo?.banner.length" :banner-list="pageInfo.banner" class="view-content" />

  <section v-if="!pending && pageInfo?.recommend" class="view-content mt-10 flex overflow-x-overlay py-2 -my-2" gap="4 md:6">
    <CommonGameRecommend v-for="game in pageInfo.recommend" :key="game.gameName" :game="game" icon-class="h-40 md:h-50 lg:h-60" />
  </section>

  <section v-if="pageInfo?.games">
    <div class="grid view-content mb-6 mt-10" grid-cols="4 sm:5 md:6 lg:8" gap="4 md:6">
      <CommonGameLite v-for="(game, i) in pageInfo.games.data" :key="game.gameId" :game="game" :lazy="i > 16" />
    </div>
    <ClientOnly>
      <div v-if="pageInfo.games.total > pageInfo.games.data.length && pageInfo.games.total > 24" class="mt-10 w-full flex-center">
        <CommonShowMore :status="pending ? EShowMoreBtnStatus.LOADING : EShowMoreBtnStatus.MORE" @click="pageNumber += 1" />
      </div>
    </ClientOnly>
  </section>
  <HomeDescription class="view-content" />
</template>
