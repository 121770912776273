import type { IIndexData } from '~/types/api'

export function handleHomeBannerImg(input: IIndexData) {
  try {
    if (input?.banner.length) {
      input.banner = input.banner.map((item) => {
        item.images = JSON.parse(item.images as any)
        return item
      })
    }
    return input
  }
  catch (e) {}
}
