<script setup lang="ts">
import SVG_DESC_FAQ from '~/assets/svg/home-desc/faq.svg?url'
import SVG_DESC_INTRO from '~/assets/svg/home-desc/intro.svg?url'
import SVG_DESC_SAFE from '~/assets/svg/home-desc/safe.svg?url'

const { t } = useI18n()

const descList = computed(() => [
  {
    icon: SVG_DESC_INTRO,
    title: t('homeDesc.introTitle'),
    desc: t('homeDesc.introContent'),
  },
  {
    icon: SVG_DESC_SAFE,
    title: t('homeDesc.safeTitle'),
    desc: t('homeDesc.safeContent'),
  },
  {
    icon: SVG_DESC_FAQ,
    title: t('homeDesc.supportTitle'),
    desc: t('homeDesc.supportContent'),
  },
])
</script>

<template>
  <section class="grid justify-between px-5" lt-md="grid-cols-1 mt-15 flex-col gap-4" md="grid-cols-3 mt-30 gap-6">
    <div v-for="block in descList" :key="block.title" class="flex flex-col rd-4 bg-gray-2" lt-md="p-5" md="p-10">
      <img :src="block.icon" class="size-15" :alt="`${block.title}-icon`">
      <span class="mt-6 text-6 c-#fff c-op-90 font-[Bebas_Neue]">{{ block.title }}</span>
      <p class="mt-3 text-3.5 c-#fff c-op-50 lh-5">
        {{ block.desc }}
      </p>
    </div>
  </section>
</template>
