<script setup lang="ts">
import { EShowMoreBtnColor, EShowMoreBtnStatus } from '@/types/components'

const props = withDefaults(defineProps<{
  text?: string
  color?: EShowMoreBtnColor
  status?: EShowMoreBtnStatus
}>(), {
  text: '',
  color: EShowMoreBtnColor.GREEN,
  status: EShowMoreBtnStatus.MORE,
})

const isLoading = computed(() => props.status === EShowMoreBtnStatus.LOADING)
</script>

<template>
  <div
    class="h-11 w-35 flex-center cursor-pointer rd-2 text-3.5 font-600"
    :class="[
      color === EShowMoreBtnColor.GREEN && 'bg-green-5 c-gray-1',
      color === EShowMoreBtnColor.BLACK && 'bg-gray-2 c-green-5',
      isLoading && 'bg-transparent pointer-events-none c-green-5!',
    ]"
  >
    <ClientOnly>
      <img v-if="isLoading" class="mie-1 size-6" src="~/assets/images/apng/green-face-loading.png" alt="loading">
    </ClientOnly>
    <span>{{ isLoading ? `${$t('common.loadingMore')}...` : (text || $t('common.showMore')) }}</span>
    <svg v-if="!isLoading" class="mis-1" :class="status === EShowMoreBtnStatus.LESS && 'rotate-180'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 7L8 9L12 7" stroke="currentcolor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </div>
</template>
