<script setup lang="ts">
import SVG_BANNER_RIGHT_TRIANGLE from '/assets/svg/banner-right-triangle.svg'
import { EGameType, type ICommonGameBlockProps } from '@/types/components'

interface Props {
  game: ICommonGameBlockProps
  lazy?: boolean
  iconClass?: any
}

defineOptions({ inheritAttrs: false })

const props = defineProps<Props>()

const gameImgUrl = computed(() => scaleImage(props.game.banner, [180, 240]))

const isAutoStart = ref(false)
const gameDetailUrl = computed(() => `/${props.game.gameType === EGameType.CLOUD ? 'games' : 'html5-games'}/${props.game.seo}.html${isAutoStart.value ? '?start=1' : ''}`)
</script>

<template>
  <NuxtLink class="group" :to="gameDetailUrl">
    <div class="transition-300 all:transition-300 group-hover:-translate-y-2">
      <div v-bind="$attrs" class="relative aspect-ratio-3/4 of-hidden" lt-md="rd-3" md="rd-4">
        <img v-if="lazy" v-lazy="gameImgUrl" class="aspect-ratio-3/4 flex-shrink-0 group-hover:scale-110" :class="iconClass" :alt="game.gameName">
        <img v-else class="aspect-ratio-3/4 flex-shrink-0 group-hover:scale-110" :class="iconClass" :src="gameImgUrl" :alt="game.gameName">

        <div class="absolute bottom-0 w-full flex-center from-#000 to-transparent from-op-50 bg-gradient-to-t pb-13.3% op-0 group-hover:op-100">
          <button
            class="ld-button pointer-events-none flex-shrink-0 px-3 py-2 ld-button-green" lg="min-w-33 h-11"
            group-hover="pointer-events-auto" @mouseenter="isAutoStart = true" @mouseleave="isAutoStart = false"
          >
            <SVG_BANNER_RIGHT_TRIANGLE class="size-1.25em" />
            <span>{{ $t('common.playNow') }}</span>
          </button>
        </div>
      </div>

      <span class="line-clamp-1 mbs-2 text-3.5 c-#fff font-600 md:hidden">{{ game.gameName }}</span>
    </div>
  </NuxtLink>
</template>

<style lang="scss" scoped>
</style>
